<template>
  <div class="VModalOrderStatus">
    <div class="VModalOrderStatus__header">
      <div class="VModalOrderStatus__name">
        Статус заказа УС
      </div>
      <div
        class="VModalOrderStatus__closeModal"
        @click="$emit('closeModal')"
      />
    </div>
    <div class="VModalOrderStatus__choice">
      <button
        :class="{ orderStatusActive: orderStatus === 'OPEN' }"
        @click="onOrderStatus('OPEN')"
      >
        Открыт
      </button>
      <button
        :class="{ orderStatusActive: orderStatus === 'CLOSED' }"
        @click="onOrderStatus('CLOSED')"
      >
        Закрыт
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VModalOrderStatus',
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['closeModal', 'updateFilter'],
  data() {
    return {}
  },
  computed: {
    orderStatus() {
      return this.filter.status
    },
  },

  methods: {
    onOrderStatus(value) {
      this.$emit('updateFilter', { name: 'status', value })
      this.$emit('closeModal')
    },
  },
}
</script>
<style lang="scss">
.VModalOrderStatus {
  width: 375px;
  height: 142px;
  border: 1px solid #e6e7eb;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  position: absolute;
  bottom: -150px;
  left: 0;
  background: #fff;
  z-index: 1;
  padding: 16px 16px 0 16px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  &__name {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
  }
  &__closeModal {
    width: 24px;
    height: 24px;
    border: 1px solid #edeef1;
    border-radius: 100%;
    background: #f7f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:before {
      content: '';
      width: 1.5px;
      height: 12px;
      background: #ec4e4e;
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotateZ(45deg);
      border-radius: 5px;
    }
    &:after {
      content: '';
      width: 1.5px;
      height: 12px;
      background: #ec4e4e;
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotateZ(-45deg);
      border-radius: 5px;
    }
  }
  &__choice {
    button {
      width: 100%;
      height: 33px;
      background: #f7f8fa;
      border-radius: 7px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: start;
      padding: 0 12px;
      border: none;
      color: #80869a;
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
  .orderStatusActive {
    background: #f8f9fe;
    color: #010d35;
  }
}
</style>
