<template>
  <div
    class="VModalRestaurant"
    :class="{ 'VModalRestaurant--selected': selectedRestaurant }"
  >
    <div class="VModalRestaurant__header">
      <div class="VModalRestaurant__name">
        Ресторан
      </div>
      <div
        class="VModalRestaurant__closeModal"
        @click="$emit('closeModal')"
      />
    </div>
    <div class="VModalRestaurant__search">
      <input
        v-model="search"
        type="text"
        placeholder="Введите название ресторана.."
      >
      <button
        v-if="selectedRestaurant"
        class="VModalRestaurant__search__reset"
        @click="onSelectedRestaurant('')"
      >
        Сбросить
      </button>
      <div class="VModalRestaurant__search__icon">
        <VIconSearch />
      </div>
    </div>
    <div class="VModalRestaurant__list">
      <div
        v-for="shop in formattedShops"
        :key="shop.shop_id"
        class="VModalRestaurant__list__item"
        :class="{
          VModalRestaurant__list__item__active:
            selectedRestaurant === shop.shop_id,
        }"
        @click="onSelectedRestaurant(shop.shop_id)"
      >
        {{ shop.shop_name }}
      </div>

      <div
        v-if="!formattedShops.length"
        class="VModalRestaurant__empty-label"
      >
        Ничего не найдено
      </div>
    </div>
  </div>
</template>

<script>
import VIconSearch from '@/assets/images/icons/v-icon-search'
import { mapGetters } from 'vuex'
export default {
  name: 'VModalRestaurant',

  components: {
    VIconSearch,
  },
  props: {
    selectedRestaurant: {
      type: String,
      default: '',
    },
    shops: {
      type: Array,
      required: true,
    },
  },
  emits: ['closeModal', 'onSelectedRestaurant'],
  data() {
    return {
      search: '',
    }
  },
  computed: {
    ...mapGetters('restaurant', ['GET_RESTAURANTS']),
    formattedShops() {
      if (this.search) {
        return this.shops.filter((e) =>
          e.shop_name.toLowerCase().includes(this.search.toLowerCase())
        )
      }
      return this.shops
    },
  },
  methods: {
    onSelectedRestaurant(value) {
      this.$emit('onSelectedRestaurant', value)
      this.$emit('closeModal')
    },
  },
}
</script>
<style lang="scss">
.VModalRestaurant {
  width: 327px;
  height: 356px;
  border: 1px solid #e6e7eb;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  position: absolute;
  bottom: -364px;
  left: 0;
  background: #fff;
  z-index: 1;
  padding: 16px;
  &--selected {
    height: 396px;
    bottom: -404px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__name {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
  }
  &__closeModal {
    width: 24px;
    height: 24px;
    border: 1px solid #edeef1;
    border-radius: 100%;
    background: #f7f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:before {
      content: '';
      width: 1.5px;
      height: 12px;
      background: #ec4e4e;
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotateZ(45deg);
      border-radius: 5px;
    }
    &:after {
      content: '';
      width: 1.5px;
      height: 12px;
      background: #ec4e4e;
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotateZ(-45deg);
      border-radius: 5px;
    }
  }
  &__search {
    position: relative;
    margin-bottom: 12px;
    input {
      width: 100%;
      height: 30px;
      background: #ffffff;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      padding: 0 14px 0 36px;
      color: #80869a;
      &:focus {
        outline: 1px solid #6764ff;
      }
    }
    &__reset {
      cursor: pointer;
      width: 100%;
      height: 30px;
      margin-top: 12px;
      border: 1px solid #ec4e4e;
      border-radius: 6px;
      background: rgba(254, 195, 195, 0.15);
      color: #ec4e4e;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
    &__icon {
      position: absolute;
      left: 13px;
      top: 7px;
    }
  }
  &__list {
    overflow: auto;
    height: 238px;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: #f7f8fa;
    }
    &::-webkit-scrollbar-thumb {
      background: #6764ff;
      border-radius: 2px;
    }

    &__item {
      width: 280px;
      padding: 8px 12px;
      margin-bottom: 8px;
      background: #f7f8fa;
      border-radius: 7px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #80869a;
      &__active {
        background: #f8f9fe;
        color: #010d35;
      }
    }
  }

  &__empty-label {
    text-align: center;
    color: #80869a;
  }
}
</style>
