<template>
  <div class="VFiltersRestaurant">
    <div class="VFiltersRestaurant__wrap">
      <div
        class="VFiltersRestaurant__tabs"
        :class="{
          VFiltersRestaurant__tabs__active:
            filterActive === 'restaurant' || selectedRestaurant,
        }"
      >
        <div
          class="VFiltersRestaurant__tabs__container"
          @click="onFilterActive('restaurant')"
        >
          <p
            v-if="!selectedRestaurant"
            class="VFiltersRestaurant__tabs__text"
          >
            Ресторан
          </p>
          <p
            v-if="selectedRestaurant"
            class="VFiltersRestaurant__tabs__text"
          >
            {{ selectedRestaurant }}
          </p>
          <VIconGrayArrow
            v-if="!selectedRestaurant"
            :class="{ iconArrow: filterActive === 'restaurant' }"
          />

          <div
            v-else
            class="crossOrderStatus"
            @click.stop.prevent="onSelectedRestaurant('')"
          >
            <VIconCross />
          </div>
        </div>
        <VModalRestaurant
          v-if="filterActive === 'restaurant'"
          v-click-outside="closeModal"
          :selected-restaurant="filter.shopId"
          :shops="shops"
          @closeModal="closeModal"
          @onSelectedRestaurant="onSelectedRestaurant"
          @resetSelectedRestaurant="resetSelectedRestaurant"
        />
      </div>

      <div
        v-if="dataType !== 'personal'"
        class="VFiltersRestaurant__tabs"
        :class="[
          {
            VFiltersRestaurant__tabs__active:
              filterActive === 'tableNumber' || tableNumber,
          },
          { VFiltersRestaurant__tabs__disabled: !filter.shopId },
        ]"
      >
        <div
          class="VFiltersRestaurant__tabs__container"
          @click="onFilterActive('searchTable')"
        >
          <p class="VFiltersRestaurant__tabs__text">
            Стол {{ tableNumber }}
          </p>
          <VIconGrayArrow
            v-if="!tableNumber"
            :class="{ iconArrow: filterActive === 'searchTable' }"
          />

          <div
            v-else
            class="crossOrderStatus"
            @click.stop.prevent="clearTable"
          >
            <VIconCross />
          </div>
        </div>
        <VModalSearchTable
          v-if="filterActive === 'searchTable'"
          v-click-outside="closeModal"
          :filter="filter"
          @updateFilter="$emit('updateFilter', $event)"
          @closeModal="closeModal"
        />
      </div>

      <div
        v-if="dataType === 'all'"
        class="VFiltersRestaurant__tabs"
        :class="{
          VFiltersRestaurant__tabs__active:
            filterActive === 'orderStatus' || statusText,
        }"
      >
        <div
          class="VFiltersRestaurant__tabs__container"
          @click="onFilterActive('orderStatus')"
        >
          <p class="VFiltersRestaurant__tabs__text">
            Статус заказа УС {{ statusText }}
          </p>
          <VIconGrayArrow
            v-if="!statusText"
            :class="{ iconArrow: filterActive === 'orderStatus' }"
          />
        </div>
        <div
          v-if="statusText"
          class="crossOrderStatus"
          @click.stop.prevent="clearStatus"
        >
          <VIconCross />
        </div>
        <VModalOrderStatus
          v-if="filterActive === 'orderStatus'"
          v-click-outside="closeModal"
          :filter="filter"
          @closeModal="closeModal"
          @updateFilter="$emit('updateFilter', $event)"
        />
      </div>

      <div
        class="VFiltersRestaurant__tabs"
        :class="[
          {
            VFiltersRestaurant__tabs__active:
              filterActive === 'choosingWaiter' || waiterIsSelected,
          },
          { VFiltersRestaurant__tabs__disabled: !filter.shopId },
        ]"
      >
        <div
          class="VFiltersRestaurant__tabs__container"
          @click="onFilterActive('choosingWaiter')"
        >
          <p class="VFiltersRestaurant__tabs__text">
            Официант
          </p>
          <VIconGrayArrow
            v-if="!waiterIsSelected"
            :class="{ iconArrow: filterActive === 'choosingWaiter' }"
          />

          <div
            v-else
            class="crossOrderStatus"
            @click.stop.prevent="clearWaiter"
          >
            <VIconCross />
          </div>
        </div>
        <VModalChoosingWaiter
          v-if="filterActive === 'choosingWaiter'"
          v-click-outside="closeModal"
          :filter="filter"
          :waiters="waiters"
          :data-type="dataType"
          :active-team="activeTeam"
          @closeModal="closeModal"
          @updateFilter="$emit('updateFilter', $event)"
        />
      </div>
    </div>
    <label
      v-if="dataType === 'all'"
      class="VFiltersRestaurant__checkbox"
    >
      <input
        :value="filter.onlyReverse"
        type="checkbox"
        class="VFiltersRestaurant__checkbox-check"
        @input="
          $emit('updateFilter', {
            name: 'onlyReverse',
            value: Number(!filter.onlyReverse),
          })
        "
      >
      <p class="VFiltersRestaurant__checkbox-text">Показать только возвраты</p>
    </label>
  </div>
</template>

<script>
import VIconGrayArrow from '@/assets/images/icons/v-icon-gray-arrow'
import VIconCross from '@/assets/images/icons/v-icon-cross'
import VModalRestaurant from '@/components/orderHistory/v-modal-restaurant'
import VModalSearchTable from '@/components/orderHistory/v-modal-search-table'
import VModalOrderStatus from '@/components/orderHistory/v-modal-order-status'
import VModalChoosingWaiter from '@/components/orderHistory/v-modal-choosing-waiter'
import { mapGetters } from 'vuex'
export default {
  name: 'VFiltersRestaurant',

  components: {
    VIconGrayArrow,
    VModalRestaurant,
    VModalSearchTable,
    VModalOrderStatus,
    VModalChoosingWaiter,
    VIconCross,
  },
  props: {
    activeTeam: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      default: () => {},
    },
    waiters: {
      type: Object,
      default: () => {},
    },
    shops: {
      type: Array,
      required: true,
    },
    dataType: {
      type: String,
      default: 'all'
    }
  },
  emits: ['updateFilter'],
  data() {
    return {
      restaurantActive: false,
      filterActive: '',
      choiceWaiter: '',
    }
  },
  computed: {
    ...mapGetters('restaurant', ['GET_RESTAURANTS']),
    selectedRestaurant() {
      if (this.filter.shopId) {
        return this.GET_RESTAURANTS.find((e) => e.id === this.filter.shopId)
          .name
      }

      return ''
    },
    statusText() {
      return this.filter.status === 'OPEN'
        ? ' - Открыт'
        : this.filter.status === 'CLOSED'
        ? ' - Закрыт'
        : ''
    },
    tableNumber() {
      return this.filter.tableNumber ? `№${this.filter.tableNumber}` : ''
    },
    waiterIsSelected() {
      return this.filter.waiter
    },
  },

  methods: {
    onFilterActive(value) {
      if (this.filterActive === value) {
        this.closeModal()
        return
      }
      this.filterActive = value
    },

    onOrderStatus(value) {
      this.orderStatus = value
      this.closeModal()
    },

    onSelectedRestaurant(value) {
      this.$emit('updateFilter', { name: 'shopId', value })
      this.closeModal()
    },

    onChoiceWaiter(value) {
      this.choiceWaiter = value
      this.closeModal()
    },

    closeModal() {
      this.filterActive = ''
    },

    clearStatus() {
      this.$emit('updateFilter', { name: 'status', value: null })
      this.closeModal()
    },

    clearTable() {
      this.$emit('updateFilter', { name: 'tableNumber', value: null })
      this.closeModal()
    },

    resetSelectedRestaurant() {
      this.selectedRestaurant = ''
      this.closeModal()
    },

    clearWaiter() {
      this.$emit('updateFilter', { name: 'waiter', value: null })
      this.closeModal()
    },
  },
}
</script>
<style lang="scss">
.VFiltersRestaurant {
  &__wrap {
    display: flex;
    align-items: center;
  }

  &__checkbox {
    display: flex;
    margin-top: 10px;
    flex-direction: row-reverse;
    align-items: center;
    margin-right: auto;

    &-check {
      margin-right: auto;
    }
  }

  &__tabs {
    cursor: pointer;
    border: 1px solid #e6e7eb;
    border-radius: 6px;
    padding: 4px 12px 4px 6px;
    margin-right: 12px;
    position: relative;
    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__text {
      margin-right: 12px;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
    }
  }
  &__tabs__active {
    border: 1px solid #6764ff;
    display: flex;
  }
  &__tabs__disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.iconArrow {
  transform: rotate(180deg);
}
.crossOrderStatus {
  cursor: pointer;
}
</style>
