<template>
  <div class="VModalSearchTable">
    <div class="VModalSearchTable__header">
      <div class="VModalSearchTable__name">
        Стол
      </div>
      <div
        class="VModalSearchTable__closeModal"
        @click="$emit('closeModal')"
      />
    </div>
    <div class="VModalSearchTable__search">
      <input
        v-model="table"
        type="text"
        placeholder="Введите номер стола.."
      >
      <div class="VModalRestaurant__search__icon">
        <VIconSearch />
      </div>
    </div>
    <div class="VModalSearchTable__button">
      <VButton @click="setTableNumber">
        Применить
      </VButton>
    </div>
  </div>
</template>

<script>
import VIconSearch from '@/assets/images/icons/v-icon-search'
import VButton from '@/components/v-button'
export default {
  name: 'VModalSearchTable',

  components: {
    VIconSearch,
    VButton,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['closeModal', 'updateFilter'],
  data() {
    return {
      table: '',
    }
  },
  computed: {},
  watch: {
    table: function (newVal) {
      if (newVal) {
        if (+newVal > 999) this.table = 999
        if (+newVal < 1) this.table = 1
      }
    },
  },
  mounted() {
    this.table = this.filter.tableNumber
  },
  methods: {
    setTableNumber() {
      if (this.table !== this.filter.tableNumber) {
        this.$emit('updateFilter', { name: 'tableNumber', value: this.table })
        this.$emit('closeModal')
      }
    },
  },
}
</script>
<style lang="scss">
.VModalSearchTable {
  width: 375px;
  height: 200px;
  border: 1px solid #e6e7eb;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  position: absolute;
  bottom: -208px;
  left: 0;
  background: #fff;
  z-index: 1;
  padding: 16px 16px 0 16px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__name {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
  }
  &__closeModal {
    width: 24px;
    height: 24px;
    border: 1px solid #edeef1;
    border-radius: 100%;
    background: #f7f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:before {
      content: '';
      width: 1.5px;
      height: 12px;
      background: #ec4e4e;
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotateZ(45deg);
      border-radius: 5px;
    }
    &:after {
      content: '';
      width: 1.5px;
      height: 12px;
      background: #ec4e4e;
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotateZ(-45deg);
      border-radius: 5px;
    }
  }
  &__search {
    position: relative;
    margin-bottom: 24px;
    input {
      width: 100%;
      height: 30px;
      background: #ffffff;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      padding: 0 14px 0 36px;
      color: #80869a;
      &:focus {
        outline: 1px solid #6764ff;
      }
    }
    &__icon {
      position: absolute;
      left: 13px;
      top: 7px;
    }
  }
}
</style>
