<template>
  <div>
    <div class="VOrderHistoryAmountsSum">
      <div class="VOrderHistoryAmountsSum__date">
        <p class="VOrderHistoryAmountsSum__date__text">
          {{ formattedDate }}
        </p>
      </div>
      <div class="VOrderHistoryAmountsSum__amounts">
        <div
          v-if="dataType === 'all'"
          class="VOrderHistoryAmountsSum__amounts__item"
        >
          <VIconTotalRubles />
          &nbsp;Итого:
          <p class="VOrderHistoryAmountsSum__amounts__item__sum">
            &nbsp;{{ getFullAmount(totalSum) }}&nbsp;&#8381;
          </p>
        </div>
        <div class="VOrderHistoryAmountsSum__amounts__item">
          <VIconTotalTips />
          &nbsp;Чаевые:
          <p class="VOrderHistoryAmountsSum__amounts__item__sum">
            &nbsp;{{ getFullAmount(totalTips) }}&nbsp;&#8381;
          </p>
        </div>
      </div>
    </div>
    <table
      v-for="session in (dataType !== 'all' ? listOfSessionsQR : listOfSessions)"
      :key="session.session_id"
      class="VOrderHistoryTableItem"
      :class="{ activeTable: activeTable === session.session_id }"
      @click="onActive(session)"
    >
      <thead>
        <tr>
          <th class="widthName">
            <p>Ресторан</p>
            <span>{{ session.shop_name }}</span>
          </th>

          <th
            v-if="dataType !== 'all'"
            class="waiter-cell"
          >
            <p>Официант</p>
            <span>{{ session.tips.restaurant_team_tips ? 'Команда заведения' : session.waiter_name }}</span>
          </th>

          <th v-if="dataType !== 'personal'">
            <p>Стол</p>
            <span>{{ session.table }}</span>
          </th>

          <th v-if="dataType !== 'all'">
            <p>№ Заказа B2P</p>
            <span>{{ session.transaction_id }}</span>
          </th>

          <th v-if="dataType === 'all'">
            <p>ID Заказа УС</p>
            <span>{{ session.session_id }}</span>
          </th>
          <th>
            <p>Дата и время</p>
            <span>{{ session.created_at }}</span>
          </th>
          <th v-if="dataType === 'all'">
            <p>Статус заказа УС</p>
            <span>{{ getStatusSession(session.status) }}</span>
          </th>
          <th v-if="dataType === 'all'">
            <p>Общая сумма</p>
            <span>{{ getFullAmount(session.total_amount) }} &#8381;</span>
          </th>
          <th v-if="dataType === 'all'">
            <p>Сумма заказа</p>
            <span>{{ getFullAmount(session.order_amount) }} &#8381;</span>
          </th>
          <th>
            <p>Чаевые</p>
            <span
              v-if="dataType !== 'all'"
              :class="{
                green: session.tips.waiter_tips?.payment_success || session.tips.restaurant_team_tips?.payment_success,
                red: !session.tips.waiter_tips?.payment_success || !session.tips.restaurant_team_tips?.payment_success
              }"
            >
              {{
                session.tips.waiter_tips ? session.tips.waiter_tips.amount / 100 :
                session.tips.restaurant_team_tips ? session.tips.restaurant_team_tips.amount / 100 : 0
              }} &#8381;
            </span>
            <span v-else>
              {{ getFullAmount(session.tips) }} &#8381;
            </span>
          </th>
          <th>
            <p>Тр.издержки</p>
            <span>{{ getFullAmount(session.fee) }}</span>
          </th>
          <th v-if="session.reverse_amount">
            <p>Сумма возврата</p>
            <span>{{ getFullAmount(session.reverse_amount) }}</span>
          </th>
          <th
            v-if="dataType === 'all'"
            class="waiter-cell"
          >
            <p>Официант</p>
            <span>{{ session.waiter_name }}</span>
          </th>
        </tr>
        <div
          v-if="activeTable === session.session_id"
          class="line"
        />
      </thead>
      <tbody v-if="activeTable === session.session_id">
        <tr
          v-for="transaction in listOfTransactions(session)"
          :key="transaction.transaction_id"
        >
          <td
            valign="top"
            align="left"
          >
            <p>Номер заказа</p>
            <span class="B2P">{{ transaction.transaction_id }}</span>
          </td>
          <td
            valign="top"
            align="left"
          >
            <p>Дата и время</p>
            <span>{{ transaction.created_at }}</span>
          </td>
          <td
            valign="top"
            align="left"
          >
            <p>Сумма заказа</p>
            <span
              :class="getStatusTransaction(transaction.transaction_state).class"
            >
              {{ getFullAmount(transaction.transaction_amount) }} &#8381;
            </span>
          </td>

          <td
            v-if="transaction.tips.waiter_tips"
            valign="top"
            align="left"
          >
            <p>Чаевые официанту</p>
            <span
              :class="{
                red: !transaction.tips.waiter_tips.payment_success && (transaction.transaction_state === 'REJECTED' || transaction.transaction_state === 'REGISTERED'),
                green: transaction.tips.waiter_tips.payment_success,
                orange: !transaction.tips.waiter_tips.payment_success && !(transaction.transaction_state === 'REJECTED' || transaction.transaction_state === 'REGISTERED'),
              }"
            >
              {{ getFullAmount(transaction.tips.waiter_tips.amount) }} &#8381;
            </span>
          </td>
          <td
            v-if="transaction.tips.hookah_tips"
            valign="top"
            align="left"
          >
            <p>Чаевые кальянщику</p>
            <span
              :class="{
                green: transaction.tips.hookah_tips.payment_success,
                orange: !transaction.tips.hookah_tips.payment_success,
              }"
            >
              {{ getFullAmount(transaction.tips.hookah_tips.amount) }} &#8381;
            </span>
          </td>
          <td
            v-if="transaction.tips.kitchener_tips"
            valign="top"
            align="left"
          >
            <p>Чаевые кухне</p>
            <span
              :class="{
                green: transaction.tips.kitchener_tips.payment_success,
                orange: !transaction.tips.kitchener_tips.payment_success,
              }"
            >
              {{ getFullAmount(transaction.tips.kitchener_tips.amount) }} &#8381;
            </span>
          </td>
          <td
            valign="top"
            align="left"
          >
            <p>Тр.издержки</p>
            <span>{{ getFullAmount(transaction.fee) }} &#8381;</span>
          </td>
          <td
            v-if="transaction.reverse_amount"
            valign="top"
            align="left"
          >
            <p>Сумма возврата</p>
            <span>{{ getFullAmount(transaction.reverse_amount) }} &#8381;</span>
          </td>
          <!--          <td-->
          <!--            valign="bottom"-->
          <!--            align="right"-->
          <!--          >-->
          <!--            <button-->
          <!--              v-if="needToPush(transaction.transaction_state)"-->
          <!--              class="pushButton"-->
          <!--              @click="pushTransaction(transaction)"-->
          <!--            >-->
          <!--              Протолкнуть-->
          <!--            </button>-->
          <!--          </td>-->
        </tr>
      </tbody>
      <div
        v-if="
          activeTable !== session.session_id &&
            checkAvailableTransactions(session)
        "
        class="arrow"
      >
        <VIconGrayArrow />
      </div>
      <div
        v-if="
          activeTable === session.session_id &&
            checkAvailableTransactions(session)
        "
        class="arrow purple"
      >
        <VIconGrayArrowPurple />
      </div>
    </table>
  </div>
</template>

<script>
import VIconGrayArrow from '@/assets/images/icons/v-icon-gray-arrow'
import VIconGrayArrowPurple from '@/assets/images/icons/v-icon-gray-arrow-purple'
import VIconTotalRubles from '@/assets/images/icons/v-icon-total-rubles'
import VIconTotalTips from '@/assets/images/icons/v-icon-total-tips'
export default {
  name: 'VOrderHistoryTableItem',
  components: {
    VIconGrayArrow,
    VIconGrayArrowPurple,
    VIconTotalRubles,
    VIconTotalTips,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    activeTable: {
      type: [Number, String],
      default: null,
    },
    dataType: {
      type: String,
      default: 'all'
    }
  },
  emits: ['activeItem'],
  data() {
    return {}
  },
  computed: {
    dateToday() {
      return new Date().toISOString().slice(0, 10)
    },
    formattedDate() {
      if (this.item.date === this.dateToday) {
        return 'Сегодня'
      } else {
        return this.item.date
      }
    },
    totalSum() {
      return this.item.total || 0
    },
    totalTips() {
      return this.item.tips || 0
    },
    listOfSessions() {
      return this.item?.sessions || []
    },
    listOfSessionsQR() {
      return this.item?.transactions || []
    },
    testTips() {
      return this.item
    }
  },
  methods: {
    onActive(session) {
      if (this.checkAvailableTransactions(session)) {
        this.$emit(
          'activeItem',
          this.activeTable === session.session_id ? null : session.session_id
        )
      }
    },
    getStatusSession(status) {
      switch (status) {
        case 'OPEN':
          return 'Открыт'
        case 'CLOSED':
          return 'Закрыт'
      }
    },
    getStatusTransaction(status) {
      switch (status) {
        case 'PAID':
          return {
            text: 'Оплачено',
            class: 'green',
          }
        case 'REJECTED':
        case 'REGISTERED':
          return {
            text: 'Отмена',
            class: 'red',
          }
        // case 'NO_PAYMENT_TO_WAITER':
        case 'NO_PAYMENT_TO_RESTAURANT':
        case 'NO_PAYMENTS_TO_1_ENTITY':
        case 'NO_PAYMENTS_TO_RESTAURANT_AND_WAITER':
        case 'NO_PAYMENTS_TO_1_ENTITY_AND_WAITER':
          return {
            text: 'Застряла',
            class: 'orange',
          }
        default:
          return {
            text: '',
            class: '',
          }
      }
    },
    needToPush(status) {
      return status !== 'PAID' && status !== 'REJECTED'
    },
    listOfTransactions(session) {
      return session?.transactions || []
    },
    async pushTransaction(transaction) {
      try {
      } catch (error) {
        console.error('Ошибка толкания', error)
      } finally {
      }
    },
    getFullAmount(total) {
      const number = parseFloat(total) / 100
      return number.toFixed(2)
    },
    checkAvailableTransactions(session) {
      return session.transactions && session.transactions.length
    },
  },
}
</script>
<style lang="scss">
.VOrderHistoryAmountsSum {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  &__date {
    margin-right: 12px;
    &__text {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
    }
  }
  &__amounts {
    display: flex;
    align-items: center;
    &__item {
      padding: 6px 8px 6px 6px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      font-weight: 400;
      font-size: 15px;
      line-height: 120%;
      &__sum {
        font-weight: 500;
        font-size: 15px;
        line-height: 120%;
      }
    }
  }
}
.VOrderHistoryTableItem {
  padding: 14px 16px 14px 16px;
  margin-bottom: 24px;
  width: 100%;
  border: 1px solid #e6e7eb;
  border-radius: 11px;
  position: relative;
  thead {
    tr {
      text-align: left;
      th {
        padding-bottom: 12px;
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #80869a;
          white-space: nowrap;
          padding-bottom: 2px;
        }
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 12px 0 8px 0;
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #80869a;
          white-space: nowrap;
          padding-bottom: 2px;
        }
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }
  }
  .arrow {
    position: absolute;
    bottom: 14px;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
  }
  .B2P {
    color: #6764ff;
  }
  .red {
    color: #ec4e4e;
  }
  .orange {
    color: #FF974C;
  }
  .green {
    color: #25c26e;
  }
  .line {
    width: 98%;
    height: 1px;
    background: #edeef1;
    position: absolute;
  }
  .pushButton {
    cursor: pointer;
    width: 102px;
    height: 30px;
    color: #fff;
    background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
    border-radius: 6px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}
.activeTable {
  padding: 14px 16px 32px 16px;
}
.widthName {
  min-width: 85px;
}
.waiter-cell span {
  white-space: break-spaces !important;
  width: 80px;
  display: inline-block;
}
</style>
