<template>
  <div>
    <table class="VBasicStuckTable">
      <thead>
        <tr>
          <th>
            <p>Ресторан</p>
            <span>{{ restName }}</span>
          </th>
          <th>
            <p>Стол</p>
            <span>{{ tableNumber }}</span>
          </th>
          <th>
            <p>№Заказа B2P</p>
            <span class="B2P">{{ orderId }}</span>
          </th>
          <th>
            <p>Дата и время</p>
            <span>{{ date }}</span>
          </th>
          <th>
            <p>Сумма заказа</p>
            <span>{{ amount }} &#8381;</span>
          </th>
          <th>
            <p>Чаевые</p>
            <span>{{ tips }} &#8381;</span>
          </th>
          <th>
            <p>Тр.издержки</p>
            <span>{{ fee }}</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>Комментарий</p>
            <span class="message">{{ errorMessage }}</span>
          </th>
          <th
            valign="bottom"
            align="right"
          >
            <button class="pushButton">
              Протолкнуть
            </button>
          </th>
          <!--          <th-->
          <!--            valign="bottom"-->
          <!--            align="right"-->
          <!--          >-->
          <!--            <div class="errorButton">-->
          <!--              <VIconError />-->
          <!--              <div class="errorButton__text">-->
          <!--                Ошибка-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </th>-->
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
// import VIconError from '@/assets/images/icons/v-icon-error'
export default {
  name: 'VBasicStuckTable',
  components: {
    // VIconError,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    restName() {
      return this.item?.shop_name || ''
    },
    tableNumber() {
      return this.item?.table || ''
    },
    orderId() {
      return this.item?.transaction_id || ''
    },
    date() {
      return this.item?.created_at || ''
    },
    tips() {
      if(this.item.tips.waiter_tips) {
        return this.getFullAmount(this.item?.tips?.waiter_tips?.amount)
      } else if (this.item.tips.restaurant_team_tips) {
        return this.getFullAmount(this.item?.tips?.restaurant_team_tips?.amount)
      } else {
        return ''
      }
    },
    errorMessage() {
      return this.item?.error_message || ''
    },
    amount() {
      return this.getFullAmount(this.item?.transaction_amount) || 0
    },
    fee() {
      return this.getFullAmount(this.item?.fee) || 0
    },
  },

  methods: {
    getFullAmount(total) {
      const number = parseFloat(total) / 100
      return number.toFixed(2)
    },
  },
}
</script>
<style lang="scss">
.VBasicStuckTable {
  padding: 14px 16px 14px 16px;
  margin-bottom: 24px;
  width: 100%;
  border: 1px solid #e6e7eb;
  border-radius: 11px;
  position: relative;
  thead {
    tr {
      text-align: left;
      th {
        padding-bottom: 12px;
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #80869a;
          white-space: nowrap;
          padding-bottom: 7px;
        }
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }
  }
  .arrow {
    position: absolute;
    bottom: 14px;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
  }
  .B2P {
    color: #6764ff;
  }
  .red {
    color: #ec4e4e;
  }
  .green {
    color: #25c26e;
  }
  .message {
    white-space: pre-wrap;
    max-width: 140px;
    display: block;
  }
  .pushButton {
    cursor: pointer;
    width: 102px;
    height: 30px;
    color: #fff;
    background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
    border-radius: 6px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}
.errorButton {
  width: 102px;
  height: 30px;
  border: 1px solid #ec4e4e;
  border-radius: 6px;
  background: #fff6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  &__text {
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #ec4e4e;
    margin-left: 6px;
  }
}
.activeTable {
  padding: 14px 16px 32px 16px;
}
</style>
