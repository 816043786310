<template>
  <div>
    <table class="VTippingTransactionsTable">
      <!--      {{ item.tips?.hookah_tips?.amount }}-->
      <thead>
        <tr>
          <th
            valign="top"
            align="left"
          >
            <p>Ресторан</p>
            <span>{{ restName }}</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>{{ tableNumber !== '0' ? 'Стол' : '' }}</p>
            <span>{{ tableNumber !== '0' ? tableNumber : '' }}</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>№ Заказа B2P</p>
            <span>{{ transactionId }}</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>Дата и время</p>
            <span>{{ date }}</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>Сумма чаевых</p>
            <span>{{ tips }} &#8381;</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>Тр.издержки</p>
            <span>{{ getFullAmount(item.fee) }} &#8381;</span>
          </th>
          <th
            valign="top"
            align="left"
          >
            <p>Официант</p>
            <span>{{ waiterName.length ? waiterName : 'Команда заведения' }}</span>
          </th>
          <th
            valign="bottom"
            align="right"
          >
            <button class="pushButton">
              Протолкнуть
            </button>
          </th>
        </tr>
      </thead>
      <tbody v-if="item.tips?.waiter_tips && activeTipsTransaction === item.transaction_id ">
        <tr>
          <td
            valign="top"
            align="left"
          >
            <p>Дата и время</p>
            <span>{{ date }}</span>
          </td>
          <td
            valign="top"
            align="left"
          >
            <p>Чаевые официанту</p>
            <span>{{ waiterTips }} &#8381;</span>
          </td>
          <td
            valign="top"
            align="left"
          >
            <p>Статус транзакции</p>
            <span :class="getStatusTransactionTips(item.tips?.waiter_tips.payment_success).class">
              {{ getStatusTransactionTips(item.tips?.waiter_tips.payment_success).text }}
            </span>
          </td>
          <!-- выпилил, не кто не вспомнил нужна или нет -->
          <!-- <td
            valign="bottom"
            align="right"
          >
            <button
              v-if="!item.tips?.waiter_tips.payment_success"
              class="pushButton"
              @click="pushTransaction(item)"
            >
              Протолкнуть
            </button>
          </td> -->
        </tr>
      </tbody>
      <tbody v-if="item.tips?.hookah_tips && activeTipsTransaction === item.transaction_id ">
        <tr>
          <td
            valign="top"
            align="left"
          >
            <p>Дата и время</p>
            <span>{{ date }}</span>
          </td>
          <td
            valign="top"
            align="left"
          >
            <p>Чаевые кальянщику</p>
            <span>{{ hookahTips }} &#8381;</span>
          </td>
          <td
            valign="top"
            align="left"
          >
            <p>Статус транзакции</p>
            <span :class="getStatusTransactionTips(item.tips?.hookah_tips.payment_success).class">
              {{ getStatusTransactionTips(item.tips?.hookah_tips.payment_success).text }}
            </span>
          </td>
          <td
            valign="bottom"
            align="right"
          >
            <button
              v-if="!item.tips?.hookah_tips.payment_success"
              class="pushButton"
              @click="pushTransaction(item)"
            >
              Протолкнуть
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-if="item.tips?.kitchener_tips && activeTipsTransaction === item.transaction_id ">
        <tr>
          <td
            valign="top"
            align="left"
          >
            <p>Дата и время</p>
            <span>{{ date }}</span>
          </td>
          <td
            valign="top"
            align="left"
          >
            <p>Чаевые кухне</p>
            <span>{{ kitchenerTips }} &#8381;</span>
          </td>
          <td
            valign="top"
            align="left"
          >
            <p>Статус транзакции</p>
            <span :class="getStatusTransactionTips(item.tips?.kitchener_tips.payment_success).class">
              {{ getStatusTransactionTips(item.tips?.kitchener_tips.payment_success).text }}
            </span>
          </td>
          <!-- выпилил, не кто не вспомнил нужна или нет -->
          <!-- <td
            valign="bottom"
            align="right"
          >
            <button
              v-if="!item.tips?.kitchener_tips.payment_success"
              class="pushButton"
              @click="pushTransaction(item)"
            >
              Протолкнуть
            </button>
          </td> -->
        </tr>
      </tbody>
      <div
        v-if="item.tips?.waiter_tips"
        class="arrow"
        @click="onActiveTipsTransaction(item.transaction_id)"
      >
        <VIconGrayArrow />
      </div>
    </table>
  </div>
</template>

<script>
import VIconGrayArrow from '@/assets/images/icons/v-icon-gray-arrow'
export default {
  name: 'VTippingTransactionsTable',
  components: {
    VIconGrayArrow
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    activeTipsTransaction: {
      type: Number,
      required: false,
      default: null
    }
  },
  emits: ['onActiveTipsTransaction'],
  data() {
    return {
      activeTable: false
    }
  },
  computed: {
    transactionId () {
      return this.item?.transaction_id || ''
    },
    restName() {
      return this.item?.shop_name || ''
    },
    tableNumber() {
      return this.item?.table || ''
    },
    date() {
      return this.item?.created_at || ''
    },
    waiterName() {
      return this.item?.waiter_name || ''
    },
    tips() {
      if(this.item.tips.restaurant_team_tips) {
        return this.getFullAmount(this.item?.tips?.restaurant_team_tips?.amount)
      } else {
        let sumWaiterTips = 0
        let sumHookahTips = 0
        let sumKitchenerTips = 0
        if(this.item?.tips?.waiter_tips)  {
          sumWaiterTips = this.item?.tips?.waiter_tips?.amount
        }
        if(this.item?.tips?.hookah_tips)  {
          sumHookahTips = this.item?.tips?.hookah_tips?.amount
        }
        if(this.item?.tips?.kitchener_tips)  {
          sumKitchenerTips = this.item?.tips?.kitchener_tips?.amount
        }

        return this.getFullAmount(sumWaiterTips + sumHookahTips + sumKitchenerTips)
      }
    },
    waiterTips() {
      return this.getFullAmount(this.item?.tips?.waiter_tips?.amount)
    },
    hookahTips() {
      return this.getFullAmount(this.item?.tips?.hookah_tips?.amount)
    },
    kitchenerTips() {
      return this.getFullAmount(this.item?.tips?.kitchener_tips?.amount)
    }

  },

  methods: {
    getFullAmount(total) {
      const number = parseFloat(total) / 100
      return number.toFixed(2)
    },
    onActiveTable() {
      this.activeTable = !this.activeTable

    },
    needToPush(status) {
      return status !== 'PAID' && status !== 'REJECTED'
    },
    async pushTransaction(item) {
      try {
      } catch (error) {
        console.error('Ошибка толкания', error)
      } finally {
      }
    },
    getStatusTransactionTips(status) {
      switch (status) {
        case true:
          return {
            text: 'Оплачено',
            class: 'green',
          }
        case false:
          return {
            text: 'Застряла',
            class: 'orange',
          }
      }
    },
    onActiveTipsTransaction(paylaod) {
      if(this.activeTipsTransaction === paylaod) {
        this.$emit('onActiveTipsTransaction', null)
      } else {
        this.$emit('onActiveTipsTransaction', paylaod)
      }
    }
  },
}
</script>
<style lang="scss">
.VTippingTransactionsTable {
  padding: 14px 16px 14px 16px;
  margin-bottom: 24px;
  width: 100%;
  border: 1px solid #e6e7eb;
  border-radius: 11px;
  position: relative;
  thead {
    tr {
      text-align: left;
      th {
        padding-bottom: 12px;
        min-width: 45px;
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #80869a;
          white-space: nowrap;
          padding-bottom: 7px;
        }
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 12px 0 8px 0;
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #80869a;
          white-space: nowrap;
          padding-bottom: 2px;
        }
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          white-space: nowrap;
        }
      }
    }
  }
  .arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
    width: 40px;
    height: 20px;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 14px;
  }
  .red {
    color: #ec4e4e;
  }
  .green {
    color: #25c26e;
  }
  .orange {
    color: #FF974C;
  }
  .pushButton {
    cursor: pointer;
    width: 102px;
    height: 30px;
    color: #fff;
    background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
    border-radius: 6px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
}
.errorButton {
  width: 102px;
  height: 30px;
  border: 1px solid #ec4e4e;
  border-radius: 6px;
  background: #fff6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  &__text {
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #ec4e4e;
    margin-left: 6px;
  }
}
.activeTable {
  padding: 14px 16px 32px 16px;
}
.testClick {
  width: 30px;
  height: 30px;
  background: red;
}
</style>
