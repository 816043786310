<template>
  <div class="vTimePeriod">
    <div
      class="vTimePeriod__switcher"
      @click="useFilter('DAY')"
    >
      <label
        for="day"
        class="vTimePeriod__switcher-label"
        :class="{ active: filter.timePeriod === 'DAY' }"
      >
        Сегодня
      </label>
    </div>
    <div
      class="vTimePeriod__switcher"
      @click="useFilter('MONTH')"
    >
      <label
        for="month"
        class="vTimePeriod__switcher-label"
        :class="{ active: filter.timePeriod === 'MONTH' }"
      >
        Месяц
      </label>
    </div>
    <div class="vTimePeriod__period">
      <div
        class="vTimePeriod__period-label"
        :class="{ active: filter.timePeriod === 'CUSTOM_PERIOD' }"
      >
        {{ period ? convertUTC : 'Выбрать период' }}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 10L12 14L16 10"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <vDatePicker
        v-model="period"
        @closed="blur"
      />
    </div>
    <vLightPreloader v-if="preloader" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vDatePicker from '@/components/v-date-picker.vue'
import { convertUtc } from '@/utils/convertUtc'
import { filterPeriod } from '@/utils/filterDate'
import vLightPreloader from '@/components/v-light-preloader'
export default {
  name: 'VTimePeriod',
  components: {
    vDatePicker,
    vLightPreloader,
  },
  props: {
    filter: {
      type: Object,
      default: () => {
        //do smthg
      },
    },
  },
  emits: ['selectFilter', 'filterTransaction', 'updateFilter'],
  data() {
    return {
      period: null,
      preloader: false,
      dropdown: false,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED', 'USER_DATA']),
    convertUTC() {
      return this.period ? convertUtc(this.period) : ''
    },
  },
  watch: {
    filter() {
      this.period = null
    },
    async period() {
      if (this.period) {

        const dates = filterPeriod(this.period)

        this.$emit('updateFilter', {
          name: 'timePeriod',
          value: 'CUSTOM_PERIOD',
        })
        this.$emit('updateFilter', {
          name: 'periodFrom',
          value: dates.startDate,
        })
        this.$emit('updateFilter', {
          name: 'periodBefore',
          value: dates.endDate,
        })
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown)
    document
      .querySelectorAll('.vTimePeriod__period input')
      .forEach((e) => (e.readOnly = true))

    const body = document.querySelector('body')
    body.classList.add('calendarPage')
  },
  unmounted() {
    document.removeEventListener('click', this.closeDropdown)

    const body = document.querySelector('body')
    body.classList.remove('calendarPage')
  },

  methods: {
    ...mapActions('transactions', ['FETCH_TRANSACTIONS']),
    blur() {
      document.querySelector(':focus').blur()
    },
    selectSort(idx, status) {
      const data = {
        idx,
        status,
      }
      // const filterArr = this.sortList.filter(i => i.active)
      this.$emit('filterTransaction', data)
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown
    },
    closeDropdown() {
      this.dropdown = false
    },
    async useFilter(value) {
      const newPeriod = value === this.filter.timePeriod ? '' : value

      this.$emit('updateFilter', { name: 'periodFrom', value: '' })
      this.$emit('updateFilter', { name: 'periodBefore', value: '' })
      this.$emit('updateFilter', { name: 'timePeriod', value: newPeriod })
      this.period = null
    },
  },
}
</script>
<style lang="scss" scoped>
.vTimePeriod {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    width: 300px;
  }
  &__switcher {
    display: flex;
    align-items: center;
    position: relative;
    &-label {
      cursor: pointer;
      padding: 6px 8px;
      font-size: 15px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      margin-right: 12px;
      &.active {
        background: #6764ff;
        color: #fff;
        border-color: #6764ff;
      }
    }
  }
  &__period {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 769px) {
      margin-top: 16px;
    }
    &-label {
      cursor: pointer;
      padding: 2px 8px;
      font-size: 15px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
      //margin-right: 12px;
      display: flex;
      align-items: center;
      &.active {
        background: #6764ff;
        color: #fff;
        border-color: #6764ff;
        svg {
          stroke: #fff;
        }
      }
    }
    svg {
      stroke: #80869a;
    }
  }
}
.mx-datepicker.mx-datepicker-range {
  left: -160px
}
</style>
