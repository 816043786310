<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.57696 4C4.22963 4 3 4.99922 3 6.39426V9.68278C3 11.0778 4.22963 12.077 5.57696 12.077H7.38471V19.0002C7.38471 19.2535 7.5126 19.4897 7.72474 19.6282C7.93688 19.7667 8.20459 19.7887 8.43651 19.6868L10.053 18.9762L11.6695 19.6868C11.8618 19.7713 12.0808 19.7713 12.2731 19.6868L13.8896 18.9762L15.5061 19.6868C15.7381 19.7887 16.0058 19.7667 16.2179 19.6282C16.43 19.4897 16.5579 19.2535 16.5579 19.0002V12.077H18.3657C19.713 12.077 20.9426 11.0778 20.9426 9.68278V6.39426C20.9426 4.99922 19.713 4 18.3657 4H5.57696ZM16.5579 10.577H18.3657C19.0371 10.577 19.4426 10.1047 19.4426 9.68278V6.39426C19.4426 5.9724 19.0371 5.5 18.3657 5.5H5.57696C4.90549 5.5 4.5 5.9724 4.5 6.39426V9.68278C4.5 10.1047 4.90549 10.577 5.57696 10.577H7.38471V8.03841C7.38471 7.6242 7.7205 7.28841 8.13471 7.28841H15.8079C16.2221 7.28841 16.5579 7.6242 16.5579 8.03841V10.577ZM8.88471 17.8512V8.78841H15.0579V17.8512L14.1914 17.4704C13.9991 17.3858 13.7801 17.3858 13.5878 17.4704L11.9713 18.1809L10.3548 17.4704C10.1625 17.3858 9.94354 17.3858 9.75122 17.4704L8.88471 17.8512ZM10.4367 9.81805C10.0225 9.81805 9.68668 10.1538 9.68668 10.568C9.68668 10.9823 10.0225 11.318 10.4367 11.318H13.506C13.9202 11.318 14.256 10.9823 14.256 10.568C14.256 10.1538 13.9202 9.81805 13.506 9.81805H10.4367ZM10.4367 12.3477C10.0225 12.3477 9.68668 12.6835 9.68668 13.0977C9.68668 13.5119 10.0225 13.8477 10.4367 13.8477H13.506C13.9202 13.8477 14.256 13.5119 14.256 13.0977C14.256 12.6835 13.9202 12.3477 13.506 12.3477H10.4367ZM10.4367 14.8773C10.0225 14.8773 9.68668 15.2131 9.68668 15.6273C9.68668 16.0415 10.0225 16.3773 10.4367 16.3773H12.7386C13.1529 16.3773 13.4886 16.0415 13.4886 15.6273C13.4886 15.2131 13.1529 14.8773 12.7386 14.8773H10.4367Z"
      fill="#80869A"
    />
  </svg>
</template>
