import { $authHost } from './api'
import { AxiosResponse } from 'axios'

enum tipsType {
  ADVANCE_CHECK = 'ADVANCE_CHECK',
  PERSONAL_QR = 'PERSONAL_QR'
}

enum timePeriod {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  CUSTOM_PERIOD = 'CUSTOM_PERIOD',
}

interface IPayload {
  tips_type?: tipsType,
  page: number,
  limit: number,
  time_period?: timePeriod,
  time_period_from?: string,
  time_period_before?: string,
  shop_id?: number,
  table_code?: string,
  waiter_id?: number,
  token: string,
  only_shop_team?: number | null
}

interface ITypeResponse {
  success: boolean
  result: {
    currentPage: number
    limit: number
    totalPage: number
    totalItem: number,
    data: IFetchQrData[]
  }
}

interface IFetchQrData {
  tips: number
  date: string
  transactions: IFetchQrTransactions[]
}

interface IFetchQrTransactions {
  transaction_id: number
  created_at: string
  transaction_amount: string
  reverse_amount: string
  tips: {
    restaurant_team_tips: {
      amount: number
      payment_success: boolean
    }
  }
  fee: number
  waiter_name: string
  hookah_name: string
  kitchener_name: string
  restaurant_team_name: string
  transaction_state: string
  error_message: string
  originator_ids: {
    complete_id: number
    purchase_id: string
    tips_ids: {
      waiter: number
      hookah: number
      kitchener: number
      team: number
    }
    reverse_id: string
  }
}

class HistoryOperationsService {
  async fetchQr(payload: IPayload): Promise<ITypeResponse> {
    const { data }:AxiosResponse<ITypeResponse> = await $authHost.get('/admin-panel/history-operations/only-qr-tips/list', {
      params: {
        tips_type: payload.tips_type,
        page: payload.page,
        limit: payload.limit,
        time_period: payload.time_period,
        time_period_from: payload.time_period_from,
        time_period_before: payload.time_period_before,
        shop_id: payload.shop_id,
        table_code: payload.table_code,
        waiter_id: payload.waiter_id,
        only_shop_team: payload.only_shop_team,
      },
      headers: {
        Authorization: 'Bearer ' + payload.token,
      },
    })
    return data
  }
}

export default new HistoryOperationsService()
