<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15L11.71 11.71M11.7138 2.8382C14.1647 5.28913 14.1647 9.26287 11.7138 11.7138C9.26287 14.1647 5.28913 14.1647 2.8382 11.7138C0.387267 9.26287 0.387267 5.28913 2.8382 2.8382C5.28913 0.387267 9.26287 0.387267 11.7138 2.8382V2.8382Z"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
